<template>
  <div>
    <div class="card">
      <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
        <div class="anan-tabs__nav">
          <div
            class="anan-tabs__nav-warp px-2 table-container"
            style="margin-top: 5px;"
          >
            <div
              class="anan-tabs__nav-tabs"
              style="transform: translateX(0px)"
            >
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '0' }"
                style="white-space: normal"
                @click="GetOrderAll(status =0), isActive = '0'"
              >
                <span> {{ $t('all') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '1' }"
                style="white-space: normal"
                @click="GetOrderAll(status =1), isActive = '1'"
              >
                <span> {{ $t('unpaid') }} </span>
              </div>
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '2' }"
                style="white-space: normal"
                @click="GetOrderAll(status =2), isActive = '2'"
              >
                <span> {{ $t('paidAmount') }} </span>
              </div>
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '3' }"
                style="white-space: normal"
                @click="GetOrderAll(status =2), isActive = '3'"
              >
                <span> {{ $t('pendingReview') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '4' }"
                style="white-space: normal"
                @click="GetOrderAll(status =4), isActive = '4'"
              >
                <span> {{ $t('Waitingfordelivery') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '5' }"
                style="white-space: normal"
                @click="GetOrderAll(status =5), isActive = '5'"
              >
                <span> {{ $t('delivered') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === '6' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 6), isActive = '6'"
              >
                <span> {{ $t('cancel') }} </span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="d-lg-flex justify-content-between align-items-center px-2">
        <div>
          <h3 class="font-weight-bolder">
            {{ $t('sendToChina') }}
          </h3>
        </div>

        <div class="d-flex align-items-center">
          <div class="anan-input__inner anan-input__inner--normal">
            <input
              v-model="search"
              type="text"
              :placeholder="this.$t('search')"
              class="anan-input__input"
              @change="GetOrderAll(status)"
            >
            <div class="anan-input__suffix">
              <i class="anan-input__clear-btn anan-icon" />
              <i class="anan-input__suffix-icon anan-icon">
                <i class="fal fa-search" />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div class="px-2 mt-1">
        <button
          type="button"
          class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
          @click="Additem()"
        >
          <span> {{ $t('shippingtoChina') }} </span>
        </button>
      </div>
      <!-- <router-link
        :to="{name: 'admin-paymenttochina-create'}"
        class="px-2 "
      >

      </router-link> -->
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>
            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(number)="data">
              <b-link :to="{name: 'admin-paymenttochina-create', params: {id: data.item._id}}">
                {{ data.item.itemnumber_text }}
              </b-link>
            </template>

            <template #cell(customer)="data">
              {{ data.item.username ? data.item.username: '-' }}
            </template>

            <template #cell(tel)="data">
              {{ data.item.tolphone ? data.item.tolphone: '-' }}
            </template>

            <template #cell(received)="data">
              {{ data.item.dateintime ? time(data.item.dateintime): '-' }}
            </template>
            <template #cell(export)="data">
              {{ data.item.dateouttime ? time(data.item.dateouttime): '-' }}
            </template>
            <template #cell(toDestination)="data">
              {{ data.item.datedestination ? time(data.item.datedestination): '-' }}
            </template>
            <template #cell(parcelCode)="data">
              {{ data.item.postnumber ? data.item.postnumber: '-' }}
            </template>

            <template #cell(price)="data">
              {{ Commas(data.item.detailspost.reduce((acc,items) => acc+ items.shipping_fee, 0)) }}
              <!-- {{ data.item.price ? data.item.price: '-' }} -->
            </template>
            <template #cell(addrCN)="data">
              {{ data.item.address ? data.item.address: '-' }}
            </template>

            <template #cell(status)="data">
              <span
                v-if="data.item.status === 0"
                class="badge badge-secondary badge-pill"
              > {{ $t('cancelled') }}
              </span>

              <span
                v-if="data.item.status === 1"
                class="badge badge-danger badge-pill"
              > {{ $t('unpaid') }} </span>
              <span
                v-if="data.item.status === 2"
                class="badge badge-success badge-pill"
              > {{ $t('paidAmount') }}
              </span>
              <span
                v-if="data.item.status === 3"
                class="badge badge-danger badge-pill"
              > {{ $t('WaitingVerification') }}
              </span>

              <span
                v-if="data.item.status === 4"
                class="badge badge-danger badge-pill"
              > {{ $t('Waitingfordelivery') }}
              </span>

              <span
                v-if="data.item.status === 5"
                class="badge badge-success badge-pill"
              > {{ $t('delivered') }} </span>
            </template>

            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="$router.push({ name: 'admin-paymenttochina-create', params: { id: data.item._id } })"
              />

              <feather-icon
                icon="Trash2Icon"
                class="text-danger cursor-pointer"
                @click="DeleteType(data.item)"
              />
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetOrderAll(status)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>

    </div>
    <!-- modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      title="รูปภาพสินค้า"
    >
      <img
        :src="pic"
        alt="..."
        class="img-fluid rounded"
      >

      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary mt-2"
          @click="downloadImg"
        >
          Download
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BLink, BModal, BTable, BSpinner, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BPagination,
    BLink,
    BModal,
    BTable,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      search: null,
      isActive: '0',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,

      items: [],
      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('customer'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'weight', label: this.$t('weightKilo'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'addrCN', label: this.$t('returnAddressChina'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'tel', label: this.$t('mobileNumber'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'received', label: this.$t('receive'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'export', label: this.$t('export'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'toDestination', label: this.$t('destinationReached'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'parcelCode', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'price', label: this.$t('price'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('status'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.GetNotify()
    this.GetOrderAll(this.status)
  },
  methods: {
    DeleteType(data) {
      this.$bvModal.msgBoxConfirm(this.$t('DeleteInformation'), {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: data._id,
          }
          this.$http.post('/Paymenttochina/DeleteData', obj)
            .then(() => {
              this.GetOrderAll()
              this.Success('ลบข้อมูลเรียบร้อย')
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-paymenttochina-edit', params: { id: data._id } })
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('YYYY-MM-DD')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    Additem() {
      this.$bvModal.msgBoxConfirm(this.$t('ConfirmInformation'), {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          this.$http.post('/Paymenttochina/StoreData')
            .then(response => {
            // eslint-disable-next-line no-underscore-dangle
              this.$router.push({ name: 'admin-paymenttochina-create', params: { id: response.data._id } })
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    GetOrderAll(type) {
      this.showOver = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search: this.search,
        type,
      }
      this.$http
        .get('/Paymenttochina/getData', { params })
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.total
          this.showOver = false
        })
        .catch(error => {
          console.log(error)
          this.SwalError(error)
          this.showOver = false
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

    <style lang="scss" scoped></style>
